import Component from '../../classes/Component';
import { $dom } from '../../helpers/dom';
import { $events } from '../../helpers/events';
import variables from '../../variables';

const { get, getAll, addClass, removeClass } = $dom;
const { active } = variables.classNames;

const productListSelector = '.js-products-list';
const productListItemSelector = '.js-products-list-item';

export function productsFunctional() {

  const clearClasses = () => {
    getAll(productListItemSelector).forEach(element => {
      removeClass(element, active)
    });
  }

  const mouseoverHandler = event => {
    let target = event.target;

    if (target.closest(productListItemSelector)) {
      target = target.closest(productListItemSelector);

      clearClasses();

      addClass(target, active);
    }
  }

  return new Component({
    name: 'productsFunctional',
    requiredTargets: productListSelector,
    onCreate() { },
    onInit() {
      $events.delegate
        .on('mouseover', productListItemSelector, mouseoverHandler)
    },
    onDestroy() {
      $events.delegate
        .off('mouseover', productListItemSelector, mouseoverHandler)
     }
  })
}