import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import { $events } from '../helpers/events';
import variables from '../variables';

const { get, getAll, attr, addClass, removeClass } = $dom;
const { active } = variables.classNames;

const headerNavigationSelector = '.js-header-navigation';
const headerNavigationItemSelector = '.js-header-navigation-item';
const headerNavigationNavSelector = '.js-header-navigation-nav';
const headerNavigationMenuSelector = '.js-header-navigation-menu';
const headerNavigationMenuItemSelector = '.js-header-navigation-menu-item';

const searchSelector = '.js-search';
const menuSelector = '.js-menu';
const tkSelector = '.js-tk';

const dataMenuTag = 'data-menu-tag';
const dataContentTag = 'data-content-tag';

export function headerNavigation() {

  const clearNavClasses = () => {
    getAll(headerNavigationItemSelector).forEach(element => {
      removeClass(element, 'is-current');
    });
    
    getAll(headerNavigationMenuItemSelector).forEach(element => {
      removeClass(element, 'is-current');
    });
  }

  const menuHandler = event => {
    let target = event.target;

    if (target.closest(headerNavigationItemSelector)) {
      target = target.closest(headerNavigationItemSelector);
      
      addClass(headerNavigationMenuSelector, active);

      // console.log(target);
      // console.log(attr(target, dataMenuTag));
      const currentContentTag = attr(target, dataMenuTag);

      clearNavClasses();

      addClass(target, 'is-current');
      addClass(get(`${headerNavigationMenuItemSelector}[${dataContentTag}=${currentContentTag}]`), 'is-current');
    }
  }

  const menuClose = event => {
    const target = event.target;

    if (target.closest(headerNavigationItemSelector) || target.closest(headerNavigationMenuSelector)) {
      // console.log('not out');
    } else {
      // console.log('out');
      removeClass(headerNavigationMenuSelector, active)
      clearNavClasses();
    }
  }

  const searchOverHandler = event => {
    const target = event.target.closest(searchSelector);

    // console.log(target);

    addClass(target, active);
    addClass(menuSelector, 'd-none');
    addClass(tkSelector, 'd-none');
    // addClass(tkSelector)
  }

  const searchOutHandler = event => {
    const target = event.target;

    if (!target.closest(searchSelector)) {
      removeClass(searchSelector, active);
      removeClass(menuSelector, 'd-none');
      removeClass(tkSelector, 'd-none');
    }

    // console.log(target);

    // addClass(tkSelector)
  }

  return new Component({
    name: 'headerMenu',
    requiredTargets: headerNavigationSelector,
    onCreate() { },
    onInit() {
      $events.delegate
        .on('mouseover', headerNavigationSelector, menuHandler)
        .on('mouseout', window, menuClose)
        .on('mouseover', searchSelector, searchOverHandler)
        .on('mouseout', window, searchOutHandler)
    },
    onDestroy() { }
  })
}